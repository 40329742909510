$cdnUtilitiesUrl: "https://cdn-bb-utility-test.cmpc-innovation.com";
@import 'variables/sizes';
@import 'mixins';
@import 'variables/colors';
@import 'fonts/fonts';

.hide {
  display: none;
}

.arrow {
  transform: rotate(180deg);
  font-size: 24px;
  line-height: 20px;
  color: $white;
}

.arrow-open {
  font-size: 24px;
  line-height: 20px;
  color: $white;
}

.hidden {
  display: none;
}

.text {
  color: $white;
  font-weight: 700;
}

.header {
  @include max-screen(sm) {
    margin-right: 0;
    width: 150px;
  }
  display: flex;
  justify-content: space-between;
  padding: 1rem 1.5rem;
  height: 48px;
  width: 320px;
  background-color: $hippie-green;
  margin-right: 4rem;
  border-radius: 10px 10px 0 0;
}

.header-mobile {

  @extend .header;
  width: 100%;
  border-radius: 0;
}

.hidden-content {
  @include max-screen(sm) {
    width: 100%;
    height: calc(100vh - 120px);
    background-color: $white;
  }
  height: 340px;
  width: 320px;
  border: 1px solid $gallery;
}

.container {
  z-index: 99;
  position: fixed;
  bottom: 0;
  right: 0;
  font-size: 0;
}

%common-close-button {
  display: flex;
  padding: 8px 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 24px;
  border: 1px solid $hippie-green;
  background: $white;
  text-transform: capitalize;
  margin-top: 8px;
  color: $hippie-green;
}

%common-close-button:hover {
  background-color: $hippie-green;
  color: $white;
  cursor: pointer;
}

.close-button {

  @extend %common-close-button;
}

.close-button:hover {

  @extend %common-close-button:hover;
}

.loading {
  height: 100%;
  background-color: $white;
  display: flex;
  justify-content: center;
  align-items: center;
}

.spinner {
  color: $hippie-green;
}
