$cdnUtilitiesUrl: "https://cdn-bb-utility-test.cmpc-innovation.com";
@import 'variables/colors';
@import 'animations';

.sidebar-arrow {
  padding-left: 10px;
  transition: width 0.25s;
}

.arrow {
  border-radius: 4px;
  height: 40px;
  width: 100%;

  &:hover {
    background-color: $gallery;
  }

  &.arrow-hover {
    width: 40px;
  }
}

.arrow-icon {
  max-width: 18px;
  min-width: 18px;
  width: 100%;
}

.arrow-clicked {
  transform: rotate(180deg);
}

.arrow-text {
  display: none;
}

.arrow-text-hover {
  animation: fadeIn 1s;
  display: block;
}
