$cdnUtilitiesUrl: "https://cdn-bb-utility-test.cmpc-innovation.com";
@import 'variables/sizes';
@import 'mixins';
@import 'variables/colors';
@import 'fonts/fonts';
@import '../../views/styles.module.scss';

.content-comment {
  @include max-screen(sm) {
    height: 270px;
  }
  background-color: $white;
  height: 80%;
  padding: 10px 8%;
}

.help-us {
  @include max-screen(sm) {
    margin-top: 35px;
  }
  color: $tones;
  font-family: $font;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  align-self: flex-start;
  margin-top: 17px;
}

.comment-box {
  width: 100%;
}

.footer-step {
  height: 20%;
  background-color: $white;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.close-button {

  @extend %common-close-button;
}

.close-button:hover {

  @extend %common-close-button:hover;
}

.send-button {
  // display: flex;
  // padding: 8px 16px;
  // justify-content: center;
  // align-items: center;
  // gap: 8px;
  // text-transform: capitalize;

  // border-radius: 24px;
  @include max-screen(sm) {
    margin: 6px 20px 0 6px;
  }
  color: $white;
  background: $hippie-green;
  margin: 6px 16px 0 6px;

  @extend %common-close-button;
}

.disable-button {

  // @extend %common-close-button;

  @include max-screen(sm) {
    margin: 6px 20px 0 6px;
    border: 1px solid $iron;
    background: $iron;
  }
  display: flex;
  padding: 8px 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 24px;
  text-transform: capitalize;
  margin-top: 8px;
  color: $white;
}
