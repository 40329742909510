/* stylelint-disable no-descending-specificity */
/* stylelint-disable declaration-no-important */
/* stylelint-disable font-family-name-quotes  */
/* stylelint-disable property-no-vendor-prefix */
@import 'variables/colors';

.full-width {
  max-width: 100%;
  width: 100%;

  input {
    max-width: 100%;
    width: 100%;
  }
}

.full-height {
  height: 100%;
}

.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.breadcrumb {
  color: $tundora;
  display: flex;
  flex-wrap: wrap;
  padding: 10px 0;

  &--alt {
    color: $white;
    font-weight: 400;
  }

  li {
    font-weight: 300;
    position: relative;
    list-style-type: none;

    &:not(:last-child) {
      &::after {
        content: '/';
        padding: 0 5px;
      }
    }
  }

  a {
    color: $white;
    font-weight: 300;

    &:not(:last-child) {
      &::after {
        content: '/';
        padding: 0 5px;
      }
    }
  }

  .active {
    color: $white;
    font-weight: 400;

    a {
      color: $white;
      font-weight: 400;
    }

    &--alt {
      color: $white;
    }
  }
}

.icon-green-bg {
  align-items: center;
  display: flex;
  justify-content: center;
  padding: 8px;
  position: relative;

  &::before {
    color: $hippie-green;
    z-index: 1;
  }

  &::after {
    background: $loafer;
    border-radius: 100%;
    content: '';
    height: 100%;
    position: absolute;
    width: 100%;
    z-index: 0;
  }
}

.hide {
  display: none;
}

.central {
  /* stylelint-disable-line */
  top: 20em;
  /* stylelint-disable-line */
  position: relative;
}

.breadcrum-display-link {
  a {
    color: $tundora !important;
    font-weight: 400;
    font-family: Lato, sans-serif;
    font-size: 16px;
  }
}

.breadcrum-active-link {
  a {
    color: $apple;
    font-weight: 400;
    font-family: 'Lato', sans-serif;
    font-size: 16px;
  }
}

.capitalize {
  text-transform: capitalize;
}

.no-select-text {
  -webkit-user-select: none;
  /* Safari */
  -ms-user-select: none;
  /* IE 10 and IE 11 */
  user-select: none;
  /* Standard syntax */
}

.flex-between {
  display: flex;
  justify-content: space-between;
}

.flex {
  display: flex;
}

.align-center {
  align-items: center;
}

$breakpoint-xs: 370px;
$breakpoint-sm: 700px;
$breakpoint-md: 1024px;
$breakpoint-lg: 1280px;
$breakpoint-xl: 1366px;

$landscape: 'landscape';