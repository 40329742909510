@import './variables/colors';
@import 'animations';

.circle-spinner {
  &::after,
  &::before {
    animation: spin 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border: 6px solid;
    border-color: $hippie-green transparent transparent transparent;
    border-radius: 50%;
    content: '';
    height: 50px;
    left: 0;
    margin: 0 auto;
    position: absolute;
    right: 0;
    top: 40%;
    width: 50px;
  }

  &::after {
    animation-delay: -0.3s;
  }

  &::before {
    animation-delay: -0.15s;
  }
}
