$cdnUtilitiesUrl: "https://cdn-bb-utility-test.cmpc-innovation.com";
@import 'variables/colors';
@import 'margins';

.badge {
  background: $trinidad;
  border-radius: 10px;
  padding: 2px 6px;
  position: absolute;
  border: 1px solid $white;

  .number-notifications {
    color: $white;
  }
}
