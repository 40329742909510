$cdnUtilitiesUrl: "https://cdn-bb-utility-test.cmpc-innovation.com";
@import 'variables/sizes';
@import 'mixins';

.container-content {
  height: 100%;
  max-width: 100%;
  overflow: auto;
  position: fixed;
  width: 100vw;

  @include min-screen(sm) {
    padding-left: calc(#{$sidebar-width} + 36px);
  }
}

.component-container {
  padding: 0 16px 30px;

  @include min-screen(xs) {
    padding: 0 16px 30px;
  }

  @include min-screen(sm) {
    padding: 0 16px 30px 0;
  }
}

.sidebar-open {
  padding-left: 0;

  @include min-screen(sm) {
    padding-left: calc(#{$sidebar-full-width} + 20px);
  }
}

.sidebar-only-mobile {
  padding-left: 0;
}
