$cdnUtilitiesUrl: "https://cdn-bb-utility-test.cmpc-innovation.com";
@import 'variables/colors';
@import 'mixins';
@import 'variables/sizes';

.option-panel {
  background-color: $white;
  border: 1px solid $iron;
  border-radius: 4px;
  position: absolute;
  right: 0;
  top: 50px;
  width: 424px;
  z-index: 6;

  @include min-screen(sm) {
    max-height: 450px;
    min-height: 175px;
  }

  .width-flag {
    width: 400px;
  }

  .header {
    padding: 20px 24px 0;
  }

  .header-flag {
    padding: 16px;
    background-color: $alabaster;
    box-shadow: 0 0 4px 0 $iron;
    position: sticky;
    top: 0;
    z-index: 10;

    @include min-screen(sm) {
      min-height: 48px;
    }
  }

  .area-notifications-wrapper {
    height: calc(100% - #{$header-height});
    padding: 0 24px;

    @include min-screen(sm) {
      min-height: 350px;
    }
  }

  .area-notifications-wrapper-no-padding {
    height: calc(100% - #{$header-height});

    @include min-screen(sm) {
      min-height: 350px;
    }
  }

  .icon-panel {
    border-radius: 4px;
    color: $cod-gray;
    cursor: pointer;
    font-size: 22px;
    height: 32px;
    padding: 4px;
    width: 32px;

    &:hover {
      background-color: $gallery;
    }
  }

  .title-notifications {
    color: $cod-gray;
  }

  .title-size {
    font-size: 16px;
  }

  .background-color-header {
    background-color: $alabaster;
  }

  .line-separator {
    border: none;
    border-top: 1px solid $gallery;
    margin-top: 10px;

    @include max-screen(sm) {
      display: none;
    }
  }

  .empty-notifications {
    height: 100%;

    @include min-screen(sm) {
      margin-top: 30px;
    }
  }

  .image-empty-notifications {
    height: 184px;
    width: 156px;
  }

  .text-empty-notifications {
    color: $tundora;
  }

  .footer {
    padding: 0 0 16px;
  }

  .show-more {
    color: $hippie-green;
    left: 0;
    text-align: center;
    width: 100%;

    &:hover {
      cursor: pointer;
    }
  }
}

.scrollable-y {
  overflow-y: auto;
}

.no-display {
  display: none;
}

.line-end {
  display: none;
}

.badge {
  display: none;
}

@include max-screen(sm) {
  .option-panel {
    border: none;
    height: calc(100% - #{$header-height});
    position: fixed;
    top: $header-height;
    width: 100vw;
  }

  .show-more {
    margin-bottom: 24px;
  }

  .line-end {
    background-color: $black;
    display: block;
    height: 5px;
    margin: auto;
    width: 134px;
  }

  .footer {
    padding: 0;
  }

  .badge {
    display: block;
    transform: translate(134px, 2px);

    .number-notifications {
      display: block;
    }
  }
}

.title-dont-read {
  color: var(--grayscale-G3, $oslo-gray);
  font-feature-settings: 'clig' off, 'liga' off;
  /* Body/p2 */
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  /* 142.857% */
}

.show-all-n-close {
  display: flex;
  align-items: center;
  gap: 24px;

  a {
    color: var(--colors-Green-1, $hippie-green);
    font-feature-settings: 'clig' off, 'liga' off;
    /* Breadcrumbs/web regular */
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    margin-left: 24px;
  }
}
