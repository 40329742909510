$cdnUtilitiesUrl: "https://cdn-bb-utility-test.cmpc-innovation.com";
@import 'variables/colors';
@import 'mixins';

.notification {
  padding: 25px 20px;
  // padding: 25px 44px;
  position: relative;

  &:not {
    border-bottom: 1px solid $gallery;
    background: $white;
    padding: 15px 40px;
  }

  &-read {
    border-bottom: 1px solid $gallery;
    background: $white;
    padding: 15px 40px;

    // &:last-child {
    //   margin-bottom: 20px;
    //   padding: 10px 37px;
    // }
  }
}

.notification-container {
  padding: 25px 44px;
  position: relative;

  &:not {
    border-bottom: 1px solid $gallery;
    background: $white;
    padding: 15px 40px;
  }

  &-read {
    border-bottom: 1px solid $gallery;
    background: $white;
    padding: 15px 40px;

    // &:last-child {
    //   margin-bottom: 20px;
    //   padding: 10px 37px;
    // }
  }
}

.hover-notification {
  padding: 25px 44px;
}

.hover-notification:hover {
  background-color: $gallery;
  cursor: pointer;
}

.notification-center {
  @include min-screen(md) {
    align-items: center;
    flex-direction: row;

    .notification-content {
      flex: 0.8;
    }

    .notification-time {
      margin-bottom: 0;
      order: 3;
      width: 100px;
    }

    .notification-actions {
      align-items: center;
      flex-direction: row;
      justify-content: space-between;
    }
  }
}

.notification-content {
  position: relative;

  .title {
    font-weight: 600;
  }

  .title,
  .description {
    color: $cod-gray;
  }
}

.notification-time {
  padding: 5px 0;
}

.circle {
  left: -20px;
  position: absolute;
  top: 6px;

  &:hover {
    cursor: pointer;
  }
}

.button-approve {
  height: 40px;
  padding: 10px;
  width: 140px;
}
