$cdnUtilitiesUrl: "https://cdn-bb-utility-test.cmpc-innovation.com";
@import 'variables/sizes';
@import 'mixins';
@import 'variables/colors';
@import 'fonts/fonts';

.thankyou-text {
  color: $cod-gray;
  text-align: center;
  font-family: $font;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
}

.content {
  @include max-screen(sm) {
    height: 25%;
    padding: 10% 15% 5%;
  }
  background-color: $white;
  height: 70%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  padding: 10% 15%;
}

.thnx-container {
  @include max-screen(sm) {
    width: 100vw;
    padding: 70% 20%;
  }
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-around;
  height: 100%;
  padding: 30% 20%;
  background-color: $white;
}

.bxb-team {
  @include max-screen(sm) {
    margin: 20px;
  }
  color: $cod-gray;
  text-align: center;
  font-family: $font;
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  line-height: 16px;
  padding-bottom: 20px;
}
