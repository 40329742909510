$cdnUtilitiesUrl: "https://cdn-bb-utility-test.cmpc-innovation.com";
@import '~wolox-equalizer/equalizer';

@import 'layout';

@import 'margins';

@import 'typography';

@import 'buttons';

@import 'global';

@import 'fonts/fonts';

@import 'variables/sizes';

@import 'animations';

@import 'spinner';

@import 'bg-img-wrapper';

@import 'select';
@import url($cdnUtilitiesUrl+'/cmpIconos/style.css');

@import 'calendar';

* {
  font-family: $font;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, Roboto, Oxygen, Ubuntu, 'Droid Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0;
  padding: 0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
