$denim: #0B43D1;
$smalt: #052E91;
$white: #FFF;
$black: #000;
$mercury: #E1E1E1;
// CMPC colors
$hippie-green: #4E824D;
$apple: #6AA43D;
$sushi: #99BC36;
$oslo-gray: #88898D;
$loafer: #F3F8E9;
// Status colors
$anzac: #E5AF43;
$blue-ribbon: #016AEA;
$pattens-blue: #DFEEFF;
$saffron: #F2BE2F;
$old-lace: #FDF6E9;
$trinidad: #D54308;
$pot-pourri: #F4E3DB;
$order-entered: #D54308;
// Grayscale
$cod-gray: #1C1C1C;
$tundora: #4A4A4A;
$switcher-gray: #8E8E8E;
$iron: #D0D2D3;
$gallery: #F0F0F0;
$alabaster: #FBFBFB;
$tones: #646464;
// status colors
$avaible: #6AA43D;
$managed: #E5AF43;
$locked: #D54308;
$transparent: #0000;

// design system
$green: #1F8100;
$default-icon: #99A7C6;
