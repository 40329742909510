/* stylelint-disable scss/at-mixin-parentheses-space-before */
@import 'global';

$breakpoints: (
  xs: $breakpoint-xs,
  sm: $breakpoint-sm,
  md: $breakpoint-md,
  lg: $breakpoint-lg,
  xl: $breakpoint-xl
);

$hover: hover;

@mixin min-screen($breakpoint) {
  $size: if(map-has-key($breakpoints, $breakpoint), map-get($breakpoints, $breakpoint), $breakpoint);

  @media only screen and (min-width: $size) {
    @content;
  }
}

// TODO: Avoid the use of this mixing
@mixin max-screen($breakpoint) {
  $size: if(map-has-key($breakpoints, $breakpoint), map-get($breakpoints, $breakpoint), $breakpoint);

  @media only screen and (max-width: $size) {
    @content;
  }
}

@mixin is-hoverable() {
  @media (hover: $hover) {
    @content;
  }
}

@mixin responsiveComponent($width: null) {
  width: 50%;

  @include min-screen(sm) {
    width: initial;
  }

  @if $width {
    @include min-screen(md) {
      width: $width;
    }
  }

  @include min-screen(lg) {
    margin-right: 0;
  }
}

@mixin print() {
  @media print {
    @content;
  }
}

@mixin fluid-type($min-font-size: 12px, $max-font-size: 21px, $lower-range: 420px, $upper-range: 900px) {
  $average-font: ($max-font-size / ($max-font-size * 0 + 1)) - ($min-font-size / ($min-font-size * 0 + 1));
  $average-range: ($upper-range / ($upper-range * 0 + 1)) - ($lower-range / ($lower-range * 0 + 1));
  font-size: calc(#{$min-font-size} + #{($average-font)} * ((100vw - #{$lower-range}) / #{($average-range)}));

  @media screen and (max-width: $lower-range) {
    font-size: $min-font-size;
  }
  @media screen and (min-width: $upper-range) {
    font-size: $max-font-size;
  }
}
