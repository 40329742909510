$cdnUtilitiesUrl: "https://cdn-bb-utility-test.cmpc-innovation.com";
@import 'variables/colors';
@import 'mixins';

.alert {
  animation: fadeIn 0.5s ease-in-out;
  border-radius: 4px;
  color: $cod-gray;
  cursor: pointer;
  max-width: 100%;
  padding: 15px;
  position: relative;
  z-index: 5;
}

.with-icon {
  padding-left: 42px;
}

.hidden {
  display: none;
}

.icon {
  font-size: 25px;
  left: 10px;
  position: absolute;
  top: 15px;
}

.info {
  background-color: $pattens-blue;
  border: 1px solid $blue-ribbon;

  .icon {
    color: $blue-ribbon;
  }
}

.error {
  background-color: $pot-pourri;
  border: 1px solid $trinidad;

  .icon {
    color: $trinidad;
  }
}

.warning {
  background-color: $old-lace;
  border: 1px solid $anzac;

  .icon {
    color: $saffron;
  }
}

.close {
  position: absolute;
  right: 10px;
}
