$cdnUtilitiesUrl: "https://cdn-bb-utility-test.cmpc-innovation.com";
@import 'variables/colors';

.option-panel {
  background-color: $white;
  border: 1px solid $iron;
  border-radius: 4px;
  position: absolute;
  right: 0;
  top: 50px;
  width: 248px;
  z-index: 2;

  .icon-panel {
    height: 20px;
  }

  .line-separator {
    border: none;
    border-top: 1px solid $gallery;
  }

  .options {
    color: $tundora;

    &:hover {
      cursor: pointer;
    }

    &:last-child {
      margin-bottom: 16px;
    }
  }

  .space-padding {
    padding: 16px 16px 0;
  }

  .username {
    color: $cod-gray;
  }
}

.no-display {
  display: none;
}
