/* stylelint-disable selector-class-pattern */

@import 'variables/colors';

.MuiPickersDay-daySelected {
  span p {
    color: $white;
  }
}

.MuiButton-label {
  .label-ok {
    color: $hippie-green;
    font-weight: 700;
  }
}
