$cdnUtilitiesUrl: "https://cdn-bb-utility-test.cmpc-innovation.com";
@import 'variables/colors';
@import 'margins';
@import 'mixins';

.container {
  align-items: center;
  background: rgba(0, 0, 0, 0.3);
  display: flex;
  height: 78px;
  justify-content: flex-end;
  left: 0;
  padding-right: 24px;
  position: relative;
  width: 100%;
  visibility: visible;

  &-transparent {
    @include min-screen(sm) {
      background-color: transparent;
    }
  }

  @include min-screen(sm) {
    align-items: flex-end;
    background-color: transparent;
  }
}

.fixed {
  z-index: 2;

  @include min-screen(sm) {
    position: initial;
    z-index: initial;
  }
}

.image-header {
  display: none;
}

.input-icon {
  font-size: 20px;
  position: absolute;

  &:hover {
    cursor: pointer;
  }
}

.oval {
  border-radius: 100%;
  color: $cod-gray;
  height: 36px;
  position: relative;
  width: 36px;
  border: 2px solid $white;

  @include min-screen(xs) {
    color: $white;
  }

  &-white {
    background-color: transparent;

    @include max-screen(sm) {
      border: $white 1.32px solid;
      color: $white;
    }

    @include min-screen(xs) {
      color: $white;
    }
  }

  &:not(:last-child) {
    margin-right: 18px;
  }
}

.oval:hover {
  cursor: pointer;
}

.icon-active {
  color: $white;
}

.badge {
  right: -16px;
  top: -16px;
  border: $white 2px solid;

  &-white {
    border: $white 2px solid;
  }
}

@include max-screen(sm) {
  .container {
    display: flex;
    justify-content: space-between;
    padding: 0 0 0 25px;

    .option-panel {
      display: none;
    }

    .image-header {
      display: block;
      max-height: 35px;
      position: relative;
    }

    .oval {
      background: none;
      border: 1px solid $white;
    }

    .notifications-panel {
      position: relative;
      width: 100%;
    }

    .badge {
      border: 1px solid $white;
      height: 18px;
      padding: 1px 4px;
      right: -8px;
      top: -10px;
      width: 33px;

      .number-notifications {
        display: block;
        font-size: 12px;
        text-align: center;
      }
    }
  }
}