@font-face {
  font-family: Lato;
  font-weight: 300;
  src: url('Lato/Lato-Light.ttf');
}

@font-face {
  font-family: Lato;
  font-weight: 600;
  src: url('Lato/Lato-Bold.ttf');
}

@font-face {
  font-family: Lato;
  font-weight: 400;
  font-style: italic;
  src: url('Lato/Lato-Italic.ttf');
}

@font-face {
  font-family: Lato;
  font-weight: 400;
  src: url('Lato/Lato-Regular.ttf');
}

$font: 'Lato', sans-serif;

@font-face {
  font-family: DMSans;
  font-weight: 300;
  src: url('DMSans/DMSans-Light.ttf');
}

@font-face {
  font-family: DMSans;
  font-weight: 600;
  src: url('DMSans/DMSans-Bold.ttf');
}

@font-face {
  font-family: DMSans;
  font-weight: 400;
  font-style: italic;
  src: url('DMSans/DMSans-Italic.ttf');
}

@font-face {
  font-family: DMSans;
  font-weight: 400;
  src: url('DMSans/DMSans-Regular.ttf');
}

$DMSans: 'DMSans', sans-serif;

@font-face {
  font-family: Montserrat;
  font-weight: 300;
  src: url('Montserrat/Montserrat-Light.ttf');
}

@font-face {
  font-family: Montserrat;
  font-weight: 600;
  src: url('Montserrat/Montserrat-Bold.ttf');
}

@font-face {
  font-family: Montserrat;
  font-weight: 400;
  font-style: italic;
  src: url('Montserrat/Montserrat-Italic.ttf');
}

@font-face {
  font-family: Montserrat;
  font-weight: 400;
  src: url('Montserrat/Montserrat-Regular.ttf');
}

$Montserrat: 'Montserrat', sans-serif;
