.bg-img-wrapper {
  @include min-screen(sm) {
    margin: 0 20px 0 0;
  }

  &::before {
    background: url('../assets/bg_screen.png') left/cover no-repeat;
    content: '';
    height: 310px;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: -1;

    @include min-screen(sm) {
      background-position-x: center;
      height: 310px;
    }

    @include max-screen(sm) {
      background: url('../assets/bg_screen_mobile.png') center/cover no-repeat;
      width: 100%;
      height: 296px;
    }

    @include min-screen(lg) {
      background-position: top;
      height: 310px;
    }
  }
}
