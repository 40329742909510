$cdnUtilitiesUrl: "https://cdn-bb-utility-test.cmpc-innovation.com";
@import 'variables/colors';

.tooltip-text {
  background-color: $tundora;
  border-radius: 4px;
  box-shadow: 0 0 4px 0 $cod-gray;
  color: $white;
  line-height: 20px;
  margin: 4px 6px;
  padding: 12px;
}
