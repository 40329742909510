@import 'variables/colors';
@import 'mixins';

// TODO: Change with project's button styles

.button {
  background-color: $white;
  border: 1px solid $iron;
  border-radius: 24px;
  color: $hippie-green;
  font-size: 18px;
  line-height: 24px;
  min-height: 48px;
  padding: 10px 20px;
  text-align: center;

  &--alt {
    border: none;
  }

  &--nopadding {
    padding: 0;
  }

  &.full {
    line-height: 48px;
    max-width: 100%;
    width: 100%;
  }

  &:disabled {
    box-shadow: none;
    color: $iron;
    cursor: not-allowed;
  }

  @include is-hoverable() {
    &:hover:not(:disabled) {
      background-color: $gallery;
    }
  }

  &:active:not(:disabled) {
    box-shadow: 0 0 4px 0 $sushi;
  }

  &.primary {
    background-color: $hippie-green;
    color: $white;

    @include is-hoverable() {
      &:hover {
        background-color: $sushi;
      }
    }

    &:active {
      background-color: $sushi;
      box-shadow: 0 0 4px 0 $sushi;
    }

    &:disabled {
      background-color: $iron;
    }
  }

  &.approve {
    font-size: 16px;
    line-height: 24px;
  }
}

.link {
  color: $hippie-green;
  font-size: 20px;
  line-height: 24px;
  min-height: 40px;

  &.center {
    text-align: center;
  }

  &:hover {
    color: $apple;
    text-decoration: underline;
  }

  &:active {
    color: $apple;
    text-decoration: underline;
  }

  &:disabled {
    color: $iron;
    cursor: auto;
    pointer-events: none;
  }

  &.text {
    font-size: 16px;
    line-height: 24px;
  }
}
