.title {
  font-size: 32px;
  line-height: 44px;
}

.sub-title {
  font-size: 18px;
  font-weight: 800;
  line-height: 22px;
  color: $hippie-green;
}

.section-title {
  font-size: 24px;
  line-height: 29px;
}

.big-text {
  font-size: 20px;
  line-height: 28px;
}

.text {
  font-size: 14px;
  line-height: 19px;
}

.messages {
  font-size: 14px;
  line-height: 20px;
}

.title,
.sub-title,
.section-title,
.big-text,
.text,
.messages {
  &.left {
    text-align: left;
  }

  &.center {
    text-align: center;
  }

  &.right {
    text-align: right;
  }

  &.dark {
    color: $cod-gray;
  }

  &.gray {
    color: $tundora;
  }

  &.white {
    color: $white;
  }

  &.error {
    color: $trinidad;
  }

  &.disabled {
    color: $iron;
  }

  &.bold {
    font-weight: 600;
  }

  &.italic {
    font-style: italic;
  }

  &.light {
    font-weight: 300;
  }

  &.inline {
    display: inline;
  }
}
