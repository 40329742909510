$cdnUtilitiesUrl: "https://cdn-bb-utility-test.cmpc-innovation.com";
@import 'variables/sizes';
@import 'mixins';
@import 'variables/colors';
@import 'fonts/fonts';
@import '../../views/styles.module.scss';

.close-button {

  @extend %common-close-button;
}

.close-button:hover {

  @extend %common-close-button:hover;
}

.content {
  @include max-screen(sm) {
    height: 25%;
    padding: 10% 15% 5%;
  }
  background-color: $white;
  height: 70%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  padding: 10% 15%;
}

.content-title {
  color: $sushi;
  text-align: center;
  font-feature-settings: 'liga' off;
  font-family: $font;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.content-question {
  color: $cod-gray;
  text-align: center;
  font-family: $font;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
}

.footer {
  @include max-screen(sm) {
    display: none;
  }
  height: 30%;
  background-color: $gallery;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
