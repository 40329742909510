$cdnUtilitiesUrl: "https://cdn-bb-utility-test.cmpc-innovation.com";
@import 'variables/sizes';
@import 'variables/colors';
@import 'mixins';
@import 'global';

@mixin sidebar-hidden {
  transform: translateX(-100%);
}

@mixin sidebar-responsive {
  background-color: $alabaster;
  border: 2px solid $gallery;
  height: 100%;
  padding: 0 12px 15px;
  position: fixed;
  transition: transform 0.25s;
  transform: translate(0);
  width: $sidebar-full-width;
  z-index: 1;
}

.sidebar {
  @include sidebar-hidden();

  @include min-screen(sm) {
    background-color: $alabaster;
    border: 2px solid $gallery;
    height: 100%;
    padding: 0 12px 32px;
    position: fixed;
    transition: width 0.5s;
    transform: translate(0);
    width: $sidebar-width;
    z-index: 1;

    &.complete {
      transition: width 0.15s;
      width: $sidebar-full-width;
    }
  }
}

.only-tablet-and-mobile {
  @include sidebar-hidden();
}

.cmpc-icon {
  border-bottom: 1px solid $gallery;
  margin-bottom: 24px;
  height: 100px;
  width: 100%;

  .complete & {
    justify-content: center;
  }
}

.cmpc-icon-off {
  height: 24px;
  transition: width 0.5s;
  width: 28px;
}

.cmpc-icon-on {
  height: 50px;
  transition: width 0.5s;
  width: auto;
}

.items-container {
  padding: 16px 0;
}

.background {
  background-color: $black;
  height: 100vh;
  position: fixed;
  opacity: 0.5;
  width: 0;
  z-index: 1;

  &-complete {
    width: 100%;
  }

  @include min-screen(sm) {
    &-complete {
      background-color: transparent;
      width: 100%;

      @include is-hoverable() {
        width: 0;
      }
    }
  }
}

@media only screen and (min-width: $breakpoint-xs) and (orientation: $landscape) and (max-width: $breakpoint-md) {
  .sidebar {
    overflow-y: auto;
  }
}

// For screens withSidebarTabletAndMobile

.sidebar-tablet-and-mobile {
  @include sidebar-responsive();
}