/* stylelint-disable scss/at-extend-no-missing-placeholder */

@import 'variables/colors';
@import 'mixins';

.react-select-container .react-select {

  &__placeholder {
    color: $tundora;
    font-size: 16px;
    line-height: 20px;

    @include min-screen(sm) {
      font-size: 16px;
      line-height: 24px;
    }
  }

  &__control {
    border: 1px solid $iron;
    min-height: 45px;
    min-width: 90px;
    width: 100%;

    @include min-screen(xs) {
      min-width: 120px;
    }

    &--is-focused,
    &:hover {
      border-color: $sushi;
      box-shadow: 0 0 4px 0 $sushi;
    }
  }

  &__value-container {
    max-height: 150px;
    overflow-y: auto;
  }

  &__menu {
    width: 100%;
    z-index: 10;
  }

  &__menu-list {
    max-height: 180px;
  }

  &__single-value {
    color: $cod-gray;
  }

  &__multi-value {
    background-color: $loafer;
    border: 1px solid $hippie-green;
    border-radius: 18px;
    color: $cod-gray;
    padding: 3px 5px;

    &__label {
      font-size: 16px;
      padding: 0 3px;
    }

    &__remove {
      cursor: pointer;

      &:hover {
        background: transparent;
      }

      &:hover svg {
        fill: $sushi;
      }
    }
  }

  &__option {
    cursor: pointer;
    min-height: 40px;
    padding: 10px 5px 5px 25px;

    &--is-selected {
      background: $white;
      color: $cod-gray;
    }

    &:hover,
    &--is-focused {
      background-color: $gallery;
    }
  }

  &__clear-indicator {
    cursor: pointer;
  }

  &__indicator {
    [class$='-LoadingDot'] {
      background-color: $hippie-green;
      height: 10px;
      margin-left: 5px;
      width: 10px;
    }
  }

  &__indicator-separator {
    display: none;
  }
}

.react-select-no-borders .react-select {

  &__control {
    border: 1px solid $white;
  }
}

.react-select-stock-header .react-select {

  @extend .react-select-container;

  @extend .react-select;

  &__placeholder {
    color: #717171;
    font-size: 16px;
    line-height: 20px;

    @include min-screen(sm) {
      font-size: 14px;
      line-height: 24px;
    }
  }
}

.react-select-container.error .react-select {
  &__placeholder {
    color: $trinidad;
  }

  &__control {
    border: 1px solid $trinidad;
    box-shadow: 0 0 4px 0 $trinidad;

    &--is-focused,
    &:hover {
      border-color: $sushi;
      box-shadow: 0 0 4px 0 $sushi;
    }
  }
}

.react-select-container.paper-selector .react-select {
  &__value-container {
    white-space: nowrap;
  }
}

.responsive-select {
  width: 100%;
  display: inline-grid;
}

// responsive-select refactorizar a todos los componentes sin agregar un div