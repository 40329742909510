$cdnUtilitiesUrl: "https://cdn-bb-utility-test.cmpc-innovation.com";
@import 'variables/sizes';
@import 'variables/colors';
@import 'animations';

.item {
  border-radius: 4px;
  height: 40px;
  margin-bottom: 8px;
  padding: 10px;
  transition: width 0.25s;
  width: 100%;

  &:last-child {
    margin-bottom: 0;
  }

  &:hover {
    background-color: $gallery;
  }

  &:active {
    background-color: $loafer;
  }

  &.item-selected {
    background-color: $loafer;
  }
}

.item-text {
  display: none;
}

.item-text-hover {
  animation: fadeIn 1.1s;
  display: block;
}

.item-icon {
  max-width: 18px;
  min-width: 18px;
  width: 100%;
}

.selected path {
  fill: $apple;
}
