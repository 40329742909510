$cdnUtilitiesUrl: "https://cdn-bb-utility-test.cmpc-innovation.com";
@import 'variables/colors';

.circle {
  border: 2px solid transparent;
  border-radius: 100%;
  height: 12px;
  min-width: 12px;
  width: 12px;
}

.info {
  background-color: $blue-ribbon;
  border-color: $blue-ribbon;
}

.action {
  background-color: $sushi;
  border-color: $sushi;
}

.urgency {
  background-color: $trinidad;
  border-color: $trinidad;
}

.disabled {
  background-color: $tundora;
  border-color: $tundora;
}

.read {
  background-color: transparent;
}
