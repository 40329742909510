$cdnUtilitiesUrl: "https://cdn-bb-utility-test.cmpc-innovation.com";
@import 'variables/colors';
@import 'mixins';

.hamburger {
  display: flex;
  height: 14px;
  padding-right: 22px;
  position: relative;
  width: 16px;

  @include min-screen(sm) {
    display: none;
  }

  &:hover {
    opacity: 0.7;
  }

  .hamburger-inner {
    display: block;
    margin-top: -2px;
    top: 1px;

    &::before,
    &::after {
      content: '';
      display: block;
    }

    &::before {
      top: 7px;
      transition: transform, opacity 0.15s ease;
    }

    &::after {
      top: 15px;
    }
  }

  .hamburger-inner,
  .hamburger-inner::before,
  .hamburger-inner::after {
    background-color: $white;
    border-radius: 4px;
    height: 2px;
    position: absolute;
    transition: transform 0.15s ease;
    width: 20px;

    @include min-screen(sm) {
      background-color: $white;
    }
  }

  &-active .hamburger-inner {
    transform: translate3d(0, 10px, 0) rotate(45deg);

    &::before {
      opacity: 0;
      transform: rotate(-45deg) translate3d(-5.71px, -1px, 0);
    }

    &::after {
      transform: translate3d(0, -15px, 0) rotate(-90deg);
    }
  }
}
